import { Link } from 'gatsby';
import * as React from 'react';
import ReactModal from 'react-modal';
import logo from '../images/fiveoak-logo.svg';
import DemoRequestModal from './demo-request-modal';

ReactModal.setAppElement('#___gatsby');

// markup
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    let demoModalOpen = false;
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      demoModalOpen = urlSearchParams.get('demopopup') === 'open';
    }
    this.state = {
      fixed: !!this.props.fixed,
      demoModalOpen: demoModalOpen,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true,
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false,
    });
  }
  render() {
    return (
      <div className={`${this.state.fixed ? 'h-20' : ''}`}>
        <header
          className={`h-20 w-full px-4 sm:px-12 bg-white flex flex-row items-center justify-between ${
            this.state.fixed ? 'fixed z-10' : ''
          }`}
        >
          <Link to="/" className="mr-2">
            <img src={logo} alt="Fiveoak" className="w-32 h-auto" />
          </Link>
          <div className="flex flex-row items-center ml-2">
            <a
              href="https://app.fiveoak.com/"
              className="text-primary-500 hover:text-primary-300"
            >
              Login
            </a>
            <button
              type="button"
              onClick={this.handleModalOpen}
              className="inline-block ml-4 cursor-pointer bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-full px-3 sm:px-12 py-2 sm:py-3"
            >
              Request a Demo
            </button>
          </div>
        </header>
        <ReactModal
          isOpen={this.state.demoModalOpen}
          contentLabel="Request a Demo"
          overlayClassName="demo-modal-overlay"
          className="demo-modal"
          onRequestClose={this.handleModalClose}
        >
          <DemoRequestModal
            onRequestClose={this.handleModalClose}
          ></DemoRequestModal>
        </ReactModal>
      </div>
    );
  }
}
