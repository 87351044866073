import * as React from 'react';
import ReactModal from 'react-modal';
import DemoRequestModal from './demo-request-modal';
import logo from '../images/fiveoak-logo.svg';
import appStoreBadge from '../images/download-on-appstore.svg';
import googlePlayBadge from '../images/get-it-on-google-play.svg';
import { Link } from 'gatsby';

ReactModal.setAppElement('#___gatsby');

// markup
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoModalOpen: false,
      hasSocialProof: props.hasSocialProof,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true,
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false,
    });
  }
  render() {
    return (
      <footer
        className={`flex flex-col items-center text-primary-300 pt-16 border-gray-100 border-t-2 ${
          this.state.hasSocialProof ? 'pb-32 lg:pb-12' : 'pb-12'
        }`}
      >
        <Link to="/" title="Fiveoak">
          <img src={logo} alt="Fiveoak" className="w-32 h-auto" />
        </Link>
        <div className="flex flex-row flex-wrap items-center my-5">
          <a
            href="https://app.fiveoak.com"
            target="_blank"
            rel="noreferrer"
            className="underline text-primary-500 hover:text-primary-300"
          >
            Login
          </a>
          <div className="w-0.5 mx-2 h-5 bg-primary-300"></div>
          <button
            type="button"
            onClick={this.handleModalOpen}
            className="underline"
          >
            Sign Up For A <strong>7 Day Free Trial</strong>
          </button>
        </div>
        <div className="flex flex-row flex-wrap items-center mb-5">
          <a
            href="https://apps.apple.com/us/app/fiveoak/id1588321916"
            target="_blank"
            rel="noreferrer"
            className="block px-1"
          >
            <img
              src={appStoreBadge}
              alt="Download on the App Store"
              className="w-auto h-12"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.fiveoak.app.twa"
            target="_blank"
            rel="noreferrer"
            className="block px-1"
          >
            <img
              src={googlePlayBadge}
              alt="Get It On Google Play"
              className="w-auto h-12"
            />
          </a>
        </div>
        <div>
          &copy; {new Date().getFullYear()} fiveoak. All rights reserved
        </div>
        <ReactModal
          isOpen={this.state.demoModalOpen}
          contentLabel="Request a Demo"
          overlayClassName="demo-modal-overlay"
          className="demo-modal"
          onRequestClose={this.handleModalClose}
        >
          <DemoRequestModal
            onRequestClose={this.handleModalClose}
          ></DemoRequestModal>
        </ReactModal>
      </footer>
    );
  }
}
