import * as React from "react";
import { IMaskInput } from 'react-imask';
import { graphql, StaticQuery } from "gatsby";
import icon from '../images/sent-request.svg';

const RECAPTCHA_SITE_KEY = '6LcFDdUaAAAAAG_zcXDnQhgPg7XlKAQ5qHu2dU5i';

// markup
class DemoRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.buttonText = 'Request a 15min demo';
    this.whiteBg = !!props.whiteBg;

    this.data = props.data;

    this.state = {
      companyName: '',
      name: '',
      phone: '',
      email: '',
      buttonEnabled: true,
      buttonText: this.buttonText,
      success: false,
      error: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.lazyLoadCaptchaLib = this.lazyLoadCaptchaLib.bind(this);
  }

  lazyLoadCaptchaLib() {
    const scriptId = 'recaptcha-script';
    if (document.getElementById(scriptId)) {
      return; // Return if script is already loaded
    }
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcFDdUaAAAAAG_zcXDnQhgPg7XlKAQ5qHu2dU5i';
    head.appendChild(script);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({
      buttonText: 'Loading...',
      buttonEnabled: false
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {action: 'submit'}).then((token) => {
        fetch(`${this.data.site.siteMetadata.apiURL}/demorequests/website`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company: this.state.companyName,
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            'g-recaptcha-response': token
          })
        }).then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        }).then(res => res.json()).then(result => {
          this.setState({
            success: true
          });
          if (window.dataLayer) {
            window.dataLayer.push({'event': 'form-demo-submit'});
          }
        }, error => {
          console.log(error);
          this.setState({
            error: true,
            buttonText: this.buttonText,
            buttonEnabled: true
          });
        });
      });
    });
  }

  render() {
    return (
      <div className="w-full">
        {!this.state.success && <form onSubmit={this.handleFormSubmit}
          className="flex flex-col w-full">
          {this.state.error && <div className="text-red-500">An unknown error occured, please try again later</div>}
          <div className="py-2">
            <label>
              Company Name
              <input type="text"
                autoCapitalize="words"
                autoComplete="organization"
                name="companyName"
                className={`field ${this.whiteBg ? 'bg-app' : ''}`}
                value={this.state.companyName}
                onFocus={this.lazyLoadCaptchaLib}
                onChange={this.handleInputChange} />
            </label>
          </div>
          <div className="flex flex-row py-2">
            <label className="pr-1 w-full">
              Name
              <input type="text"
                autoCapitalize="words"
                autoComplete="name"
                name="name"
                className={`field ${this.whiteBg ? 'bg-app' : ''}`}
                required
                value={this.state.name}
                onFocus={this.lazyLoadCaptchaLib}
                onChange={this.handleInputChange} />
            </label>
            <label className="pl-1 w-full">
              Phone
              <IMaskInput
                mask="(000) 000-0000"
                type="tel"
                autoComplete="tel"
                name="phone"
                className={`field ${this.whiteBg ? 'bg-app' : ''}`}
                required
                value={this.state.phone}
                onFocus={this.lazyLoadCaptchaLib}
                onAccept={(value, mask) => {
                  this.setState({phone: value});
                }} />
            </label>
          </div>
          <div className="py-2">
            <label>
              Email Address
              <input type="email"
                autoComplete="email"
                name="email"
                className={`field ${this.whiteBg ? 'bg-app' : ''}`}
                required
                value={this.state.email}
                onFocus={this.lazyLoadCaptchaLib}
                onChange={this.handleInputChange} />
            </label>
          </div>
          <div className="py-3">
            <button type="submit"
              disabled={!this.state.buttonEnabled}
              className="block w-full cursor-pointer bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-full px-3 sm:px-12 py-2 sm:py-3">
              {this.state.buttonText}
            </button>
          </div>

          <p className="text-xs">
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className="text-orange-500" rel="noreferrer" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" className="text-orange-500" rel="noreferrer" target="_blank">Terms of Service</a> apply.
          </p>
        </form>}
        {this.state.success && <div className="flex flex-col items-center text-primary-300">
          <h4 className="text-4xl text-primary-500 my-4 text-center">
            Request Sent
          </h4>
          <img src={icon} alt="Paper Airplane Icon" className="w-20 h-auto my-4" />
          <p className="text-center my-4">Someone from our team will be in touch with you shortly. Thank you!</p>
        </div>}
      </div>
    )
  }
}

const renderedForm = ({ whiteBg }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `}
    render={(data) => (
      <DemoRequestForm data={data} whiteBg={whiteBg} />
    )} />
);

export default renderedForm;
