import * as React from "react";
import lightbox from "../images/img-lightbox.png";
import DemoRequestForm from "./demo-request-form";

// markup
export default class DemoRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.onRequestClose = props.onRequestClose;
    this.handleCloseClick = this.handleCloseClick.bind(this);
    if (window.dataLayer) {
      window.dataLayer.push({'event': 'modal-demo-open'});
    }
  }
  handleCloseClick(event) {
    this.onRequestClose && this.onRequestClose(event);
  }
  render() {
    return (
      <div className="relative flex flex-col sm:flex-row w-full h-full items-stretch">
        <div className="bg-app flex flex-col items-center justify-center text-center sm:w-1/2 w-full p-10">
          <h3 className="text-4xl font-black my-4">Let's make you the obvious choice.</h3>
          <p className="text-primary-300 my-4">Signup to receive a 1:1 guided tour and get pricing for your company.</p>
          <img src={lightbox} alt="Search Results With Ratings"
            className="my-4" />
        </div>
        <div className="bg-white flex flex-col items-stretch justify-center sm:w-1/2 w-full p-8">
          <DemoRequestForm whiteBg={true}></DemoRequestForm>
        </div>
        <button type="button"
          onClick={this.handleCloseClick}
          className="text-primary-500 hover:text-primary-600 text-4xl absolute top-2 right-2 leading-4"
          >&times;</button>
      </div>
    );
  }
}
